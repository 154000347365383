import { gql } from '@apollo/client';

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordMutation($input: ForgotPasswordMutationInput!) {
    forgotPassword(input: $input)
      @rest(
        type: "Void"
        path: "auth/v1/users/password/recover"
        method: "POST"
        endpoint: "authentication"
        bodySerializer: "auth"
      ) {
      _
    }
  }
`;
