import { Banner, BannerHeading, BannerIcon, Button, Form, Spacer } from '@aircall/tractor-v2';
import { FormWrapper, ButtonWrapper } from '@components/LoginLayout/Login.styles';
import { FormField, validateEmail } from '@dashboard/library';
import { AnimatePresence } from 'framer-motion';
import { Form as RFForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export interface ForgottenPasswordFormValues {
  email: string;
}

export interface IForgottenPasswordFormProps {
  onSubmit: (values: ForgottenPasswordFormValues) => void;
  disabled?: boolean;
}

export function ForgottenPasswordForm({ onSubmit, disabled }: IForgottenPasswordFormProps) {
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <RFForm
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, hasValidationErrors, submitError }) => (
          <Form onSubmit={handleSubmit}>
            <Spacer space='m' direction='vertical' fluid>
              {/* Email */}
              <FormField
                name='email'
                label={t('app.form.email.label')}
                placeholder={t('forgotten_password.form.email.placeholder')}
                validate={validateEmail}
                disabled={disabled}
                getErrorMessage={t}
                data-test='email-input-forgot-password'
              />
              <AnimatePresence>
                {submitError && (
                  <Banner variant='critical' key='password-reset-err' inline>
                    <BannerIcon />
                    <BannerHeading>{t(submitError)}</BannerHeading>
                  </Banner>
                )}
              </AnimatePresence>
            </Spacer>
            <ButtonWrapper>
              <Button
                type='submit'
                data-test='reset-button-forgot-password'
                disabled={pristine || hasValidationErrors || disabled}
                size='large'
                block
              >
                {t('forgotten_password.form.submit.label')}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      />
    </FormWrapper>
  );
}
