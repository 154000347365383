import { LoginLayout } from '../../components/LoginLayout/LoginLayout';

import { ForgottenPasswordForm } from './form/ForgottenPasswordForm';
import { useForgotPassword } from './hooks/useForgotPassword';

export default function ForgottenPassword() {
  const { isResetting, handleForgotPassword } = useForgotPassword();

  return (
    <LoginLayout data-test='forgotten-password-page'>
      <ForgottenPasswordForm onSubmit={handleForgotPassword} disabled={isResetting} />
    </LoginLayout>
  );
}
