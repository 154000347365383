import { useCallback } from 'react';

import { ForgottenPasswordFormValues } from '../form/ForgottenPasswordForm';

import { LOGIN_ROUTE } from '@constants/routes.constants';
import {
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
} from '@generated/ForgotPasswordMutation';
import { FORGOT_PASSWORD_MUTATION } from '@graphql/mutations/ForgotPasswordMutation';
import { ClientException, GenericFormError, handleFormErrors } from '@helpers/errors.helpers';
import { useGraphMutation } from '@hooks/useMutation';
import { useLocation, useNavigate } from 'react-router';

export interface UseForgotPasswordReturnType {
  handleForgotPassword: ({
    email,
  }: ForgottenPasswordFormValues) => Promise<Record<string, string> | GenericFormError | null>;
  isResetting: boolean;
}

/**
 * Hook to handle forgotten passwords.
 * @returns an object holding the state and the handler to reset the password
 */
export function useForgotPassword(): UseForgotPasswordReturnType {
  const navigate = useNavigate();
  const location = useLocation();

  const [forgotPassword, { loading: isResetting }] = useGraphMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(FORGOT_PASSWORD_MUTATION, {
    onCompleted: () => {
      navigate(LOGIN_ROUTE, { state: { from: location.pathname, passwordResetEmailSent: true } });
    },
  });

  const handleForgotPassword = useCallback(
    async ({ email }: ForgottenPasswordFormValues) => {
      try {
        await forgotPassword({
          variables: {
            input: {
              email,
            },
          },
        });

        return null;
      } catch (error) {
        return handleFormErrors(error as ClientException);
      }
    },
    [forgotPassword]
  );

  return { isResetting, handleForgotPassword };
}
